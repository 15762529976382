<template>
  <b-modal 
    id="layout-select-modal" 
    centered
    size="xl"
  >
  <template #modal-header>
    <div class="header mb-2">
      <p>Buscar layout de despesas</p>
      <Close @click="$bvModal.hide('layout-select-modal')" />
    </div>
  </template>

    <Filters class="mb-3" :filters="filters"/>
    <Table 
      :loading="loading"
      :message="message"
      :layouts="layouts"
      @view="viewExpenses"
      withCheckbox
      withView
    />
    <template #modal-footer>
      <b-button variant="outline-danger" @click="$bvModal.hide('layout-select-modal')">
        Cancelar
      </b-button>
      <b-button variant="primary" @click="addLayouts">
        Salvar
      </b-button>
    </template>

    <ExpensesModal 
      :expenses="layout?.expenses"
    />
  </b-modal>
</template>

<script>
export default {
  components: {
    Filters: () => import('@/components/Tiss/Generic/Filters/LayoutsFilter.vue'),
    Table: () => import('@/components/Tiss/Table/LayoutsTable.vue'),
    Close: () => import('@/assets/icons/close.svg'),
    ExpensesModal: () => import('@/components/Tiss/Modal/ExpensesModal.vue')
  },
  props: {
    selectedLayouts: {
      type: Array,
      default: () => [],
    },
    clinicHealthPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      layout: {
        expenses: [],
      },
      layouts: [],
      message: 'Busque por um pacote para facilitar o preenchimento da guia TISS',

      filters: {
        name: null,
        type: ['Todos', 'Despesas', 'Procedimentos'],
      },

      loading: false,
    }
  },
  methods: {
    addLayouts() {
      const selected = this.layouts.filter(layout => layout.selected)
      this.$emit('addLayouts', selected)
      this.$bvModal.hide('layout-select-modal')
    },
    viewExpenses(index) {
      this.layout = this.layouts[index]
      this.$bvModal.show('expenses-modal')
    },
    async searchLayouts() {
      try {
        this.loading = true
        const { data } = await this.api.getLayouts(this.clinicHealthPlan?.id, 1, this.filters)

        data.data.map(newLayout => {
          if ( !this.layouts.find(layout => layout.id === newLayout.id) ) {
            const isSelected = this.selectedLayouts.find(layout => layout.id === newLayout.id)
            this.$set(newLayout, 'selected', !!isSelected)
            this.layouts.push(newLayout)
          }
        })  

      } catch (error) {
        const msg = 'Não foi possível carregar os layouts, contate o suporte se o erro persistir.'
        this.$toast.error(msg)
        this.message = msg
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    filters: {
      async handler() {
        if (!this.filters?.name || this.filters?.name?.length < 3) return

        await this.searchLayouts()
      },
      deep: true,
    },
    selectedLayouts() {
      this.layouts.map(layout => {
          layout.selected = !!(this.selectedLayouts.find(el => el.id === layout.id))
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-self: center;
    padding-top: 5px;
  
  p {
    font-weight: 500;
    font-size: 18px;
    color: var(--type-active);
  }
  
  svg {
    height: 24px;
    width: 24px;
    fill: var(--neutral-500);
    cursor: pointer;
  }
}

</style>